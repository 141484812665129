import './already-subscribed.styles.scss'
//import { Link } from "gatsby"
import Link from '../Link'

function AlreadySubscribed() {
	return (
		<div className="already-subscribed-wrapper">
			<h2>You are already subscribed to this plan</h2>
			<Link to="/pricing">Back To Pricing Page</Link>
		</div>
	)
}

export default AlreadySubscribed
