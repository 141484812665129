import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { usePlan, useCustomer, usePlans } from '../hooks/stripe'
import { useSWRConfig } from 'swr'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/trial.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
//import { Link } from 'gatsby';
import Link from '../components/Link'
import 'antd/dist/antd.css'
import Helmet from 'react-helmet'
import { Button, Col, Row } from 'antd'
import { Form, Input, InputNumber, Select } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import visa from '../images/bank_VISA.svg'
import master from '../images/bank_Mastercard.svg'
import amex from '../images/bank_AMEX.svg'
import discover from '../images/bank_Discover.svg'
import customerCover from '../images/customer-cover.svg'
import operationsCover from '../images/operations-cover.svg'
import employeeCover from '../images/employee-cover.svg'
import actionableCover from '../images/actionable-cover.svg'
import servicesCover from '../images/services-cover.svg'
import customer from '../images/customer.svg'
import operations from '../images/operations.svg'
import employee from '../images/employee.svg'
import actionable from '../images/actionable.svg'
import services from '../images/services.svg'
import { getUserInfo } from '../components/util/auth'
import addStripeCustomer from '../components/util/addStripeCustomer'
import updateStripeCustomer from '../components/util/updateStripeCustomer'
import { navigate } from 'gatsby-link'
import { isAuthenticated, login } from '../components/util/auth'
import tracking from '../utils/tracking'
import COUNTRIES_LIST from '../utils/countries'
import AlreadySubscribed from '../components/already-subscribed/already-subscribed.component.jsx'

import {
	Elements,
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js'
import createSubscription from '../components/util/createSubscription'
import cancelStripeSubscription from '../components/util/cancelStripeSubscription'

const isBrowser = typeof window !== 'undefined'

const stripeKey =
	process.env.NODE_ENV === 'production'
		? 'https://icloud-ready.com/stripe_customer_id'
		: 'https://icloud-ready.com/stripe_customer_id2'

function calcOverallPrice(plan, usersCount) {
	if (plan?.transform_usage) {
		const round = plan?.transform_usage.round === 'up' ? Math.ceil : Math.floor
		return (plan?.amount / 100) * round(usersCount / plan?.transform_usage.divide_by)
	}

	return (plan?.amount / 100) * usersCount
}

function calcPricePerUser(plan) {
	return plan?.amount / 100 / (plan?.transform_usage?.divide_by || 1)
}

function Spinner() {
	return <div className="spinner"></div>
}

const invitationCode = process.env.INVITATION_CODE

const validationSchema = Yup.object().shape({
	email: Yup.string().email().required(),
	// phone: Yup.string().min(7).required(),
	postalCode: Yup.string().min(4),
	commercial: Yup.string(),
	companyName: Yup.string().required(),
	country: Yup.string().required(),
	invitationCode: Yup.string().test('is-valid', 'Invalid invitation code!', value => value === invitationCode),
})

const initialValues = {
	email: '',
	// phone: "",
	postalCode: '',
	commercial: '',
	companyName: '',
	country: 'US',
	invitationCode: '',
	// phonePrefix: "20"
}

const initialStripeErrors = {
	display: false,
	cardNumber: false,
	cardExpiry: false,
	cardCvc: false,
	message: null,
}

const Trial = ({ data, location }) => {
	const [active, setActive] = useState(true)
	const { data: plan, isLoading } = usePlan(location.state, active ? 'monthly' : 'annually')
	const { mutate } = useSWRConfig()
	const [isPaymentLoading, setIsPaymentLoading] = useState(false)
	const [paymentIntent, setPaymentIntent] = useState(null)
	const [messages, _setMessages] = useState('')
	const [usersCount, setUsersCount] = useState(plan?.metadata.minUsers)
	const [stripeErrors, setStripeErrors] = React.useReducer((s, a) => ({ ...s, ...a }), initialStripeErrors)
	const { data: customer } = useCustomer()
	const pricePerUser = plan?.amount / 100 / (plan?.transform_usage?.divide_by || 1)

	// console.log(customer);
	// console.log({ plan });
	React.useLayoutEffect(() => {
		if (!isAuthenticated()) login()
	}, [])

	React.useLayoutEffect(() => {
		if ((!isLoading && !plan) || !location.state) navigate('/pricing')
	}, [])

	let stripe
	let elements
	const user = getUserInfo()
	// if (isBrowser){
	stripe = useStripe()
	elements = useElements()
	//  console.log(stripe, elements);
	// }
	// if (!stripe || !elements) {
	//  return ""
	// }
	const options = {
		appearance: {
			theme: 'stripe',
		},
		style: {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	}

	const handleSubmit = async values => {
		try {
			// event.preventDefault();
			setIsPaymentLoading(true)
			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				return
			}
			const res = await addStripeCustomer({
				name: user.name,
				email: user.email,
			})
			console.log('res', res)

			const metadata = {
				company: values.companyName,
				postalCode: values.postalCode,
				commercial: values.commercial,
				country: values.country,
			}

			updateStripeCustomer({
				customerId:
					process.env.NODE_ENV === 'production'
						? user?.['https://icloud-ready.com/stripe_customer_id']
						: user?.['https://icloud-ready.com/stripe_customer_id2'],
				metadata,
			})

			const lineItems = [
				{
					price: plan?.id,
					quantity: usersCount,
				},
			]
			const subscription = {
				items: [
					{
						plan: plan?.id,
						quantity: usersCount,
					},
				],
			}

			const subCreationResponse = await createSubscription({
				stripeId:
					process.env.NODE_ENV === 'production'
						? user?.['https://icloud-ready.com/stripe_customer_id']
						: user?.['https://icloud-ready.com/stripe_customer_id2'],
				customerEmail: user?.email,
				lineItems: lineItems,
				metaData: {},
				subscription: subscription,
				subscriptionSuccessUrl: '/',
			})

			const data = await subCreationResponse.json()
			// console.log({ data });
			const subscriptionId = data?.subscriptionId || {}
			const clientSecret = data?.clientSecret || {}
			const isTrial = data?.isTrial
			// console.log({ clientSecret });

			const card = elements.getElement('cardNumber')

			// const payload = await stripe.createPaymentMethod({
			//     type: 'card',
			//     card: elements.getElement(CardNumberElement),
			// });

			if (!isTrial) {
				let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
					payment_method: {
						card,
						billing_details: {
							address: {
								country: values.country,
								postal_code: values.postalCode,
							},
							email: values.email,
							// phone: `${values.phonePrefix}${values.phone}`
						},
						metadata: {
							company: values.companyName,
							commercial: values.commercial,
						},
					},
				})

				console.log(error)

				if (error) {
					await cancelStripeSubscription({
						subscriptionId,
						stripeId:
							process.env.NODE_ENV === 'production'
								? user?.['https://icloud-ready.com/stripe_customer_id']
								: user?.['https://icloud-ready.com/stripe_customer_id2'],
					})

					throw { type: 'card_error' }
				}

				// console.log(paymentIntent);
			}

			await new Promise(resolve => setTimeout(resolve, 1500))

			navigate('/completion', {
				state: {
					name: plan?.nickname,
					app_url: data?.tenantURL,
				},
			})

			// console.log("paymentIntent result after calling confirmCardPayment"+ JSON.stringify(paymentIntent));
		} catch (error) {
			setStripeErrors({
				message:
					error.type === 'card_error'
						? 'Invalid Card Information!'
						: 'The process has failed, Please try again!',
			})
			setIsPaymentLoading(false)
			return
		}
		setIsPaymentLoading(false)
		setPaymentIntent(paymentIntent)
	}
	console.log(paymentIntent)
	//   if (paymentIntent && paymentIntent.status === "succeeded") {
	//     navigate('/account');
	//  }

	const themeCase = [
		{
			productName: 'Customer Engagement',
			background: customerCover,
			image: customer,
		},
		{
			productName: 'Operations Excellence',
			background: operationsCover,
			image: operations,
		},
		{
			productName: 'Employees Productivity',
			background: employeeCover,
			image: employee,
		},
		{
			productName: 'Actionable Insights',
			background: actionableCover,
			image: actionable,
		},
		{ productName: 'Services', background: servicesCover, image: services },
		{ productName: 'Market Place', background: servicesCover, image: services },
	]
	const handleBackground = () => {
		return 'url(' + servicesCover + ') center/cover no-repeat'
	}
	const handleLayer = () => {
		return 'url(' + services + ') center/cover no-repeat'
	}

	const { Option } = Select
	const recaptchaRef = useRef(null)
	const [passCaptcha, setPassCaptcha] = useState(false)
	const siteKey = process.env.GOOGLE_RECAPTHCA
	const onReCaptchaChange = () => {
		setPassCaptcha(recaptchaRef.current.getValue() ? true : false)
	}
	const prefixSelector = (
		<Form.Item name="phonePrefix" noStyle>
			<Select
				className="phone-prefix"
				name="phonePrefix"
				style={{
					width: 70,
				}}
			>
				<Option value="20">+20</Option>
				<Option value="87">+87</Option>
			</Select>
		</Form.Item>
	)

	const handleStripeElmentChange = e => {
		const isError = e.error || !e.complete ? true : false
		setStripeErrors({ [e.elementType]: !isError, message: null })
	}
	// console.log(user)

	if (isLoading) return null

	if (plan?.isSubscribed && plan?.metadata.planType === 'monthly') {
		return (
			<Layout>
				<div className="signup-page" style={{ background: handleLayer() }}>
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div>
						<AlreadySubscribed />
					</div>
				</div>
			</Layout>
		)
	}

	return (
		<Layout>
			<Helmet>
				<script src="https://www.google.com/recaptcha/api.js?hl=en" async defer></script>
			</Helmet>
			<SEO title="Signup page" description="Signup a free trial with icloud-ready" />
			<div className="signup-page" style={{ background: handleLayer() }}>
				<div className="Header-parent">
					<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
				</div>
				<div className="page-content" style={{ background: handleBackground() }}>
					<div className="step-content signup-step" id="step2">
						<div className="step-parent">
							<div className="main-wrapper">
								<div style={{ height: '100px' }}></div>
								{/* <p className="title">Get the first 30 days free</p>
                <p className="subtitle">
                  Subscripe Now to get the first 30 days free , Cancel any time
                  , you will never charges before the trial period
                </p> */}
								<Formik
									initialValues={{
										...initialValues,
										email: user.email || '',
										companyName: customer?.metadata.company || '',
										commercial: customer?.metadata.commercial || '',
										postalCode: customer?.metadata.postalCode || '',
										country: customer?.metadata.country || '',
									}}
									validateOnChange={false}
									validateOnBlur={false}
									validationSchema={validationSchema}
									onSubmit={(values, { resetForm }) => {
										// console.log('triggered')
										if (
											[
												'cardNumber',
												'cardExpiry',
												'cardCvc',
											].some(key => !stripeErrors[key])
										) {
											setStripeErrors({ display: true })
										} else {
											// console.log('submitting....')
											handleSubmit(values)
										}
									}}
								>
									<Form layout="vertical">
										<div className="form-content">
											<div className="left-form">
												<div className="field-one">
													{stripeErrors.message && (
														<div className="error-msg">
															{
																stripeErrors.message
															}
														</div>
													)}
													<img
														src={discover}
														alt="discover"
														className="one"
													/>
													<img
														src={amex}
														alt="amex"
														className="two"
													/>
													<img
														src={master}
														alt="master"
														className="three"
													/>
													<img
														src={visa}
														alt="visa"
														className="four"
													/>
													<Form.Item
														name="cardNumber"
														label="Card number"
														className="field-parent "
													>
														<CardNumberElement
															className="stripe-field"
															options={
																options
															}
															onChange={
																handleStripeElmentChange
															}
														/>
														{stripeErrors.display &&
															!stripeErrors.cardNumber && (
																<div className="error-msg">
																	Enter
																	a
																	valid
																	card
																	number
																</div>
															)}
													</Form.Item>
												</div>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item
															name="expiry"
															label="Expiry"
															className="field-parent"
														>
															<CardExpiryElement
																className="stripe-field"
																options={
																	options
																}
																onChange={
																	handleStripeElmentChange
																}
															/>
															{stripeErrors.display &&
																!stripeErrors.cardExpiry && (
																	<div className="error-msg">
																		enter
																		a
																		valid
																		expiry
																		date
																	</div>
																)}
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															name="cvc"
															label="CVC"
															className="field-parent"
														>
															<CardCvcElement
																className="stripe-field"
																options={
																	options
																}
																onChange={
																	handleStripeElmentChange
																}
															/>
															{stripeErrors.display &&
																!stripeErrors.cardCvc && (
																	<div className="error-msg">
																		enter
																		a
																		valid
																		card
																		cvc
																	</div>
																)}
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item
															name="country"
															label="Country"
															className="field-parent"
														>
															<Select
																name="country"
																className="field"
																showSearch
															>
																{COUNTRIES_LIST.map(
																	c => (
																		<Option
																			value={
																				c.code
																			}
																			key={
																				c.code
																			}
																		>
																			{
																				c.name
																			}
																		</Option>
																	)
																)}
															</Select>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															name="postalCode"
															label="Postal code"
															className="field-parent"
														>
															<InputNumber
																name="postalCode"
																type="number"
																placeholder="90210"
																minLength={
																	3
																}
																maxLength={
																	10
																}
																className="field"
																style={{
																	width: '100%',
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item
															name="email"
															label="E-mail"
															className="field-parent"
														>
															<Input
																name="email"
																className="field"
																disabled={
																	true
																}
															/>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															name="commercial"
															label="Commercial Register Number"
															className="field-parent"
														>
															<InputNumber
																name="commercial"
																type="number"
																placeholder="33366666666"
																className="field"
																style={{
																	width: '100%',
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item
															name="companyName"
															label="Company Name"
															className="field-parent"
														>
															<Input
																name="companyName"
																placeholder="Company"
																className="field"
																style={{
																	width: '100%',
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															name="invitationCode"
															label="Invitation Code"
															className="field-parent"
														>
															<Input
																className="field invitation-code"
																placeholder="xxxxxxxx"
																name="invitationCode"
															/>
														</Form.Item>
													</Col>

													{/* <Form.Item
                              name="phone"
                              label="Mobile Number"
                              className="field-parent"
                            >
                              <Input
                                addonBefore={prefixSelector}
                                placeholder=" 1010657278"
                                className="field phone-number"
                                name="phone"
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Form.Item> */}
												</Row>

												<p className="hint">
													By filling out this form and
													clicking submit, you agree to
													our{' '}
													<Link
														to="/"
														className="terms"
														data-track-location="trial"
													>
														Terms & Conditions
													</Link>
												</p>
												<div className="captcha">
													<ReCAPTCHA
														ref={recaptchaRef}
														sitekey={siteKey}
														onChange={
															onReCaptchaChange
														}
													/>
												</div>
												<Button
													// onClick={e => handleSubmit(e)}
													type="primary"
													htmlType="submit"
													disabled={
														!stripe ||
														isPaymentLoading
													}
												>
													{isPaymentLoading ? (
														<Spinner />
													) : (
														'Pay'
													)}
												</Button>
											</div>
											<div className="right-form">
												<div className="field-group rows">
													<div className="row-item">
														<p className="form-title">
															Your Plan :{' '}
														</p>
														<p className="form-value">
															Starter
														</p>
													</div>
													{/* <Form.Item name="monthly">
                              <Button
                                type="primary"
                                name="monthly"
                                htmlType="button"
                              >
                                Monthly
                              </Button>
                            </Form.Item> */}
												</div>
												<div className="row-item">
													<p className="form-title">
														For :
													</p>
													<p className="form-value">
														{plan?.nickname}
													</p>
												</div>
												<div className="row-item billing">
													<p className="form-title">
														Billing :
													</p>
													<div className="field-group bill">
														<Form.Item name="monthly">
															<Button
																type={`${
																	active
																		? 'primary'
																		: 'default'
																}`}
																name="monthly"
																htmlType="button"
																onClick={() => {
																	setActive(
																		true
																	)
																}}
															>
																Monthly
															</Button>
														</Form.Item>
														<Form.Item name="annually">
															<Button
																type={`${
																	!active
																		? 'primary'
																		: 'default'
																}`}
																name="annually"
																htmlType="button"
																onClick={() =>
																	setActive(
																		false
																	)
																}
															>
																Annually
															</Button>
														</Form.Item>
													</div>
												</div>

												<div className="field-group user">
													<Form.Item
														name="users"
														label="Users"
														className="field-parent"
													>
														<InputNumber
															name="users"
															type="number"
															placeholder="5"
															value={
																usersCount
															}
															defaultValue={
																plan
																	?.metadata
																	.minUsers
															}
															min={
																plan
																	?.metadata
																	.minUsers
															}
															className="field"
															onChange={value =>
																setUsersCount(
																	value
																)
															}
															style={{
																width: '100%',
															}}
														/>
													</Form.Item>
													<p className="cost">
														{calcPricePerUser(
															plan
														)}{' '}
														$ / User /{' '}
														{active
															? 'Month'
															: 'Year'}{' '}
														after Trial
													</p>
												</div>
												<div className="line"></div>
												<div className="field-group">
													<p className="form-title">
														Due after{' '}
														{active
															? '30 Days'
															: '1 Year'}
														:{' '}
													</p>
													<p className="form-value">
														{calcOverallPrice(
															plan,
															usersCount
														)}{' '}
														$
													</p>
												</div>
												<div className="line"></div>
												<div className="field-group">
													<p className="form-title">
														Due Today :{' '}
													</p>
													<p className="form-value">
														0 $
													</p>
												</div>
												{/* <Form.Item name="submit">
                            <Button
                              // disabled={passCaptcha ? "" : "disabled"}
                              type="primary"
                              htmlType="submit"
                              name="submit"
                              onClick={() => handleSubmit()}
                            >
                              Start Free Trial
                            </Button>
                          </Form.Item> */}
											</div>
										</div>
									</Form>
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export const pageQuery = graphql`
	query TrialQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
	}
`

function PageWithPlans(props) {
	const { isLoading } = usePlans()

	if (isLoading) return null

	return <Trial {...props} />
}

function HOC(Page) {
	return function (props) {
		const isBrowser = typeof window !== 'undefined'

		if (!isBrowser) return <div></div>
		if (!props.location.state || !props.location.state.planName) {
			props.location.state = JSON.parse(localStorage.getItem('state') || null)
		}

		// console.log('Trial state', props.location.state)

		if (!isAuthenticated()) {
			login()
			return <div>loading...</div>
		}

		if (!props.location || !props.location.state) {
			navigate('/pricing')
			return <div>loading...</div>
		}

		return <Page {...props} />
	}
}

function withPlans(Page) {
	return function WithPlans(props) {
		const { data, isLoading } = usePlans()

		if (isLoading) {
			return (
				<div
					style={{
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					loading...
				</div>
			)
		}

		return <Page {...props} />
	}
}

export default HOC(withPlans(PageWithPlans))
